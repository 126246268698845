import React, { useState, useRef } from 'react';

function CustomTooltip({ children, tooltipText }) {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);
  const targetRef = useRef(null);

  function setPaddingZero(elements) {
    elements.forEach((element) => {
      element.classList.add('zero-padding');
    });
  }

  function removePaddingZero(elements) {
    elements.forEach((element) => {
      element.classList.remove('zero-padding');
    });
  }

  const handleMouseOver = () => {
    setShowTooltip(true);
    //const tdElements = document.querySelectorAll('td');
    //setPaddingZero(tdElements);

  };

  const handleMouseOut = () => {
    setShowTooltip(false);
    //const tdElements = document.querySelectorAll('td');
    //removePaddingZero(tdElements);
  };

  return (
  
    <div ref={targetRef} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      {children}
      
      <div ref={tooltipRef} className="tooltip" style={{ display: showTooltip ? 'block' : 'none' }}>
        <p>{tooltipText}</p>
      </div>
    </div>
   
  );
}

export default CustomTooltip;