/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Model(props) {
  const { nodes, materials } = useGLTF("/models/Office.glb");
  return (
    <group {...props} dispose={null} position={[0, 0, -1]}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Back_low003_furniture_0.geometry}
        material={materials.furniture}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.chair_furniture_0.geometry}
        material={materials["furniture.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube002_furniture_0.geometry}
        material={materials.furniture}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube003_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube004_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube006_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube007_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube008_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube009_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube010_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube012_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube014_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube016_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube018_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube019_bookcase_0.geometry}
        material={materials.bookcase}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube020_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube021_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube036_furniture_0.geometry}
        material={materials.furniture}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube053_furniture_0.geometry}
        material={materials["furniture.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder_furniture_0.geometry}
        material={materials.furniture}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder001_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder002_furniture_0.geometry}
        material={materials.furniture}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder004_bookcase_0.geometry}
        material={materials.bookcase}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder005_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder006_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder007_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder008_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder009_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder010_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder016_bookcase_0.geometry}
        material={materials.bookcase}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder017_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder019_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.grain_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Icosphere_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Icosphere003_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.lamps001_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.lamps002_details_0.geometry}
        material={materials.details}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pachira_aquatica_01_bark_c002_bookcase_0.geometry}
        material={materials.bookcase}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.paintings_bookcase_0.geometry}
        material={materials.bookcase}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane_furniture_0.geometry}
        material={materials.furniture}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003_bookcase_0.geometry}
        material={materials.bookcase}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane005_bookcase_0.geometry}
        material={materials.bookcase}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane006_bookcase_0.geometry}
        material={materials.bookcase}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane007_bookcase_0.geometry}
        material={materials.bookcase}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.walls_wall_0.geometry}
        material={materials.wall}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.walls001_wall_0.geometry}
        material={materials.wall}
      />
    </group>
  );
}

useGLTF.preload("/models/Office.glb");
