import React, { useEffect, useState, useRef, useCallback, Suspense } from "react";
import Webcam from "react-webcam";
import RecordRTC from "recordrtc";
import {Collapse} from 'react-collapse';
import Popup from './Popup';
import CustomTooltip from "./Tooltip";
import language from "../../assets/PNG/voice.png";
import face from "../../assets/PNG/face.png";
import burst from "../../assets/PNG/burst.png";
import ner from "../../assets/PNG/ner.png";
import prosody from "../../assets/PNG/prosody.png";
import axios from "axios";
import { set } from "lodash";
import { IoCloseCircleOutline } from "react-icons/io5";

export default function WebcamVideo({client}) {
    
    const [npcText, setNpcText] = useState(client.npcText);

    useEffect(() => {
        
        setNpcText(client.npcText);

    }, [client]);



    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [recording, setRecording] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [result, setResult] = useState([]);
    const [finalResult, setFinalResult] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [evaluation, setEvaluation] = useState(null);

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const handleStartCaptureClick = useCallback(() => {
        setRecording(true);
        mediaRecorderRef.current = RecordRTC(webcamRef.current.stream, {
            type: "video",
        });
        mediaRecorderRef.current.startRecording();
    }
        , [webcamRef, setRecording]);

    const handleStopCaptureClick = useCallback(() => {
        setRecording(false);
        mediaRecorderRef.current.stopRecording(() => {
            const blob = mediaRecorderRef.current.getBlob();
            setRecordedChunks([blob]);
            let data = new FormData()
            data.append('video', blob, 'video')
            fetch('https://api.lum10s.com/upload-evaluate', {
            //fetch('http://127.0.0.1:5000/upload-evaluate', {
                method: 'POST',
                mode: 'cors',
                body: data
            }).then(
                res => res.json()
            ).then(
                res => {
                    setResult((prevResults) => [...prevResults, res]);
                    console.log(res);
                }
            );

        });
    }, [setRecording, mediaRecorderRef]);



    // Add keydown for start and keyup for stop
    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.key === 'T' || event.key === 't') && !recording) {
                handleStartCaptureClick();
            }
        };

        const handleKeyUp = (event) => {
            if ((event.key === 'T' || event.key === 't') && recording) {
                handleStopCaptureClick();
            }

        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [handleStartCaptureClick, handleStopCaptureClick, recording]);


    function averageDicts(dicts) {
        const result = {};
      
        console.log(dicts);
        // Iterate over each dictionary in the list
        for (const dict of dicts) {
          // Iterate over each key-value pair in the current dictionary
          for (const key in dict) {
            // If the key doesn't exist in the result, initialize it with 0
            if (!result.hasOwnProperty(key)) {
              result[key] = 0;
            }
      
            // Add the value to the corresponding key in the result
            result[key] += dict[key];
          }
        }
      
        // Calculate the average for each key
        for (const key in result) {
          result[key] /= dicts.length;
          result[key] = parseInt(result[key]);
        }
      
        return result;
    }

    const handleFinalResults = useCallback(() => {

        setIsModalOpen(true);

        // Use the averageDicts function to calculate the average of the results
        const avgResults = averageDicts(result);

        console.log(avgResults);

        setFinalResult(avgResults);

        console.log(evaluation);
        

    }, [setFinalResult, finalResult, setResult, result]);

    const handleFinalResults_old = useCallback(() => {

        setIsModalOpen(true);

        // Use the averageDicts function to calculate the average of the results
        const avgResults = averageDicts(result);

        console.log(avgResults);

        setFinalResult(avgResults);

        // Get "evaluation" from local storage
        //localStorage.setItem('evaluation', "Good job! You did well in the interview."); 
        const evaluationTxt = localStorage.getItem('evaluation');//.replace("\n", "<br>");

        if (evaluationTxt) {
            const hereResuls = evaluationTxt.replace(/\n/g, "\n").replace("\n\n", "\n"); //evaluationTxt.split(":")[0];
            const sentences = evaluationTxt.split(":").slice(1, -1).join(":").split(/\w+\./);
            const q1 = sentences[0];
            const q2 = sentences[1];
            const q3 = sentences[2];
            const finalRes = sentences.slice(3,-1).join();

            setEvaluation([hereResuls, q1, q2, q3, finalRes]);
        }
        console.log(evaluation);
        

    }, [setFinalResult, finalResult, setResult, result]);

    useEffect(() => {
        // handle final results
        // check if npcText contains "here are your results"
        if (npcText.includes("Here are your results")) {
            setEvaluation(npcText)
            handleFinalResults();
        }
    }
    , [npcText]);



    return (
        <div className="Container">

            <div className="above__camera__container">
                <div>
                    <p>1. PRESS "T" AND SAY "HI HOW ARE YOU?" AND THEN RELEASE IT TO START THE INTERVIEW</p>
                    <p>2. AFTER THE INTERVIEW, CLICK ON "GET RESULTS"</p>
                    
                </div>
            
            </div>
            
            
            <Webcam
                muted="muted"
                audio={true}
                mirrored
                ref={webcamRef}
                style={{
                    //position: 'absolute',
                    //left: '50%',
                    //transform: 'translate(-50%, 0)',
                    //zIndex: 1,
                    width: '95%',
                    borderRadius: 30,
                }}
            />
            {recordedChunks.length > 0 && (
                    //<button onClick={handleOpenModal}>GET FINAL RESULTS!</button>
                    //<button onClick={handleDownload}></button>
                    <div>
                    <br></br>
                    <button onClick={handleFinalResults}>GET RESULTS!</button>
                    </div>
                    
            )}

            

            <Popup isOpen={isModalOpen} onClose={handleCloseModal}>

            <div className="modal-header" style={{position: 'fixed', left: '84%', top: '12%'}}>
            <button className="close_button" onClick={handleCloseModal}><IoCloseCircleOutline className="close-icon" /></button>
            </div>
            
            <h1>Final Results</h1>

            {evaluation === null? ( 
                <div>
                    <p>Please, finish the interview to see your final score.</p>
                    <p>Here's your average score of each type of data collected from the interview, until the present moment.</p>
                </div>
                
            ) : (
                <div>
                <p>{evaluation}</p>
                
                </div>
             )}

            
            

            {finalResult === null? (
                <Suspense fallback={<div>Loading...</div>}></Suspense>

            ) : (
                <div>
                    
                <table>
                <thead>
                <tr>
                    <td>Emotional Voice Data</td>
                    <td>Facial Data</td>
                    <td>Burst Data</td>
                    <td>Named Entity <br></br>Recognition (NER)</td>
                    <td>Prosody Data</td>
                </tr>
                <tr>
                    <td>
                        <CustomTooltip tooltipText="Emotional Voice Data: Analyzes tone for emotions like anxiety and calmness.">
                        <img src={language} alt="Language Icon" width="100" height="100" />
                        </CustomTooltip>
                    </td>
                    <td>
                        <CustomTooltip tooltipText="Facial Data: Tracks expressions like smiles and brow raises.">
                        <img src={face} alt="Face Icon" width="100" height="100" />
                        </CustomTooltip>
                    </td>
                    <td>
                        <CustomTooltip tooltipText="Burst Data: Measures speech pace, rhythm, and pauses.">
                        <img src={burst} alt="Burst Icon" width="100" height="100" />
                        </CustomTooltip>
                    </td>
                    <td>
                        <CustomTooltip tooltipText="Named Entity Recognition (NER): Monitors correct use of names and terms.">
                        <img src={ner} alt="NER Icon" width="100" height="100" />
                        </CustomTooltip>
                    </td>
                    <td>
                        <CustomTooltip tooltipText="Prosody Data: Assesses tone, pitch, and intonation in speech.">
                        <img src={prosody} alt="Prosody Icon" width="100" height="100" />
                        </CustomTooltip>
                    </td>
                </tr>
                </thead>
                <tbody>
                

                <tr>
                    <td>{finalResult.language}</td>
                    <td>{finalResult.face}</td>
                    <td>{finalResult.burst}</td>
                    <td>{finalResult.ner}</td>
                    <td>{finalResult.prosody}</td>
                </tr>
                </tbody>
            </table>
            <p>-1 = Mostly negative emotions; 0 = Mostly neutral emotions; 1 = Mostly positive emotions;</p>
            </div>
            )}
            

            </Popup>

        </div>
        

    );
};

