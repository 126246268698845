import React, { useState } from 'react';
import Modal from 'react-modal';
import { IoCloseCircleOutline } from "react-icons/io5";

const Popup = ({ children, isOpen, closeModal }) => {

  //const [isModalOpen, setIsModalOpen] = useState(true);
  //const handleOpenModal = () => setIsModalOpen(true);
  //const handleCloseModal = () => setIsModalOpen(false);

  if (!isOpen) return null;

  return (
    <div >
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className={`modal ${isOpen ? 'open' : ''}`}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.65)',
            animation: 'dropTop 0.5s linear',
            

            zIndex: 998,
          },
          content: {
            position: 'absolute',
            top: '10%',   

            left: '20%',
            //transform: 'translate(-50%, -50%)',
            width: '60%', // Adjust width as needed
            height: '70%',
            padding: '3%',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '5px',
            overflow: 'scroll',
            overflowX: 'hidden',
            //textAlign: 'center',
            //display: 'flex', // Add display: flex
            //justifyContent: 'center', // Center content horizontally
            //margin: 0 
          },
        }}
      >
        

          { children }
      
        
      </Modal>
    </div>
  );
};

export default Popup;